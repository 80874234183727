import React from "react";
import styled from "@emotion/styled";
import { Box, Container, Heading, Grid, Flex, Text } from "theme-ui";
import { graphql } from "gatsby";

import BlogCard from "../components/BlogCard";
import Footer from "../components/Footer";
import Header from "../components/Header";
import gatsbyPluginThemeUi from "../gatsby-plugin-theme-ui";
import SEO from "../components/SEO";

// import styles from ""

// simple styled component
const BlogImage = styled.img`
  height: auto;
  max-width: 100%;
  margin-bottom: 20px;
`;

export default function BlogPostTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const post = data.mainPost.edges[0].node;
  const relatedPosts = data.restPosts.edges;

  const { title, date, thumbnail } = post.frontmatter;
  const { html } = post;


  // Main article is the Box component with dangerously injected HTML, styled
  return (
    <>
      <SEO title={title} />
      <Header heroVariant="small" />

      <Container as="article" py={[14, null, null, 17]}>
        <Flex sx={{ flexWrap: "wrap", justifyContent: "space-between" }}>
          <Box sx={{ flex: ["0 0 100%", null, null, "0 0 60%"] }}>
            <Box mb={8}>
              <Heading as="h1" mb={5} variant="headingLG">
                {title}
              </Heading>
              <Text as="p">{date}</Text>
            </Box>

            <BlogImage src={thumbnail} alt="blog image" />

            <Box
              dangerouslySetInnerHTML={{ __html: html }}
              sx={{
                ...gatsbyPluginThemeUi.styles,
                p: {
                  mt: 0,
                  mb: 6,
                },
                "h2,h3": {
                  mt: 10,
                  mb: 4,
                },
                img: {
                  display: "block",
                  mb: 3,
                },
              }}
            ></Box>
          </Box>

          <Box sx={{ flex: ["0 0 100%", null, null, "0 0 30%"] }}>
            <Heading
              as="h3"
              mb={7}
              sx={{ textAlign: ["center", null, null, "left"] }}
            >
              Recent News
            </Heading>

            <Grid
              sx={{
                gridTemplateColumns: "1 100%",
                justifyContent: ["center", null, null, "space-between"],
                rowGap: [10, null, null, 10],
              }}
            >
              {relatedPosts.map(post => (
                <BlogCard
                  key={post.slug}
                  blogCategory="Dosis Updates"
                  blogCategoryColor="primary"
                  blogDate={post.date}
                  blogExcerpt={null}
                  blogImageURL={post.node.thumbnail}
                  blogLinkURL={post.node.frontmatter.slug}
                  imageFound={post.node.frontmatter.thumbnail}
                  blogTitle={post.node.frontmatter.title}
                  sx={{ maxWidth: "500px" }}
                />
              ))}
            </Grid>
          </Box>
        </Flex>
      </Container>

      <Footer />
    </>
  );
}

export const query = graphql`
  query($slug: String!) {
    mainPost: allMarkdownRemark(
      filter: { frontmatter: { slug: { eq: $slug } } }
    ) {
      edges {
        node {
          frontmatter {
            slug
            title
            date(formatString: "MMMM DD, YYYY")
            thumbnail
          }
          html
        }
      }
    }
    restPosts: allMarkdownRemark(
      filter: { frontmatter: { slug: { nin: [$slug, null] } } }
    ) {
      edges {
        node {
          frontmatter {
            slug
            title
            date(formatString: "MMMM DD, YYYY")
            description
            thumbnail
          }
        }
      }
    }
  }
`;
